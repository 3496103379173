import { Injectable } from '@angular/core';
 
import { DataService } from './data.service';
import { UtilitiesService } from './utilities.service';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  items = []
  constructor(public data : DataService,public loading : UtilitiesService,public cart : CartService) {
    console.log('wishlist init')
    if(data.isLoggedIn()){
        this.data.get('mywishlist').subscribe(data => {
          this.items = data
        })
    }
    this.loading.getObservable().subscribe(data => {
    if(data['event'] == 'login'){
      if(data['value'] == true && this.data.isLoggedIn()){
        
        console.log('sync wishlist')
         this.syncWishList();
      }
    }
  })
   }

   syncWishList(){
    if(this.data.isLoggedIn()){
      this.data.get('mywishlist').subscribe(data => {
        this.items = data
      })
  }
   }

   reset(){
     this.items = [];
   }

   is_item_in_wishlist(campaign){
    let index = this.items.findIndex((i) => {
      return i.campaign.id == campaign
    })

    if(index >= 0){
      return true
    }else{
      return false
    }


  }


   add_to_wishlist(item){
     if(!this.data.isLoggedIn()){
       this.loading.toast('Please Log In...','danger');
       return;
     }
     let index = this.items.findIndex(i => {
       return i.campaign.id == item.id
     })

     if(index >= 0){
       this.loading.toast('Item already in wislist','danger');
       return
     }else{
        
       let add = {
         campaign : item.id,
         product : item.product.id
       }
       this.data.post('add_to_wishlist',add).subscribe(data => {
         this.items = data;
         this.loading.toast('Item Added To Wishlist Successfully','success')
       })
     }
   }


   add_to_cart(item){
     this.cart.add_to_cart(item.campaign,1);
     this.remove_wishlist(item.campaign , false)
   }


   remove_wishlist(item, notice = true){
     let index = this.items.findIndex(i => {
       return i.campaign.id == item.id
     })

     if(index < 0){
       this.loading.toast('Item not found','danger');
       return
     }else{
        
       let add = {
         campaign : this.items[index].id,
         
       }
       this.data.post('remove_wishlist',add).subscribe(data => {
         this.items = data;
         if(notice){
          this.loading.toast('Item Removed From Wishlist Successfully','success')
         }
       })
     }
   }
}
