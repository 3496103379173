import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { GraphQlService } from './graph-ql.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cart_items = [];
  total_products = 0;
  shipping_cost = 0;
  shipping_status = '';
  total = 0
  payable = 0;
  products = []
  constructor(public data: DataService, public loading: UtilitiesService, public graphql: GraphQlService) {
    if (this.data.isLoggedIn()) {
      this.data.get('mycart').subscribe(data => {
        this.cart_items = data;
        this.update_total()
      })
    } else {
      if (localStorage.getItem('cart')) {
        this.cart_items = JSON.parse(localStorage.getItem('cart'))
        this.update_total()
      }

      let q = `query{
      products{
        id
        product_name_english
        product_name_arabic
        product_images{
          id
          url
        }
      }
    }`;

      this.graphql.get_public(q).subscribe(data => {
        this.products = data['data']['products']

      })
    }


    this.loading.getObservable().subscribe(data => {

      if (data['event'] == 'login') {
        if (data['value'] == true && this.data.isLoggedIn()) {
          console.clear();
          console.log('sync cart')
          let CI = []
          let x = 0;
          for (x; x < this.cart_items.length; x++) {
            CI.push({
              campaign: this.cart_items[x].campaign.id,
              product: this.cart_items[x].product.id,
              quantity: this.cart_items[x].quantity
            })
          }
          this.data.post('syncMyCart', { items: CI }).subscribe(data => {
            this.cart_items = data['cart_items'];
            this.update_total()
          })
        }
      }
    })


  }


  get_product_name_s(id) {

    let name = ''
    let index = this.products.findIndex(i => {
      return i.id == id
    })
    if (index >= 0) {
      if (this.loading.selected_lang == 'ar') {
        name = this.products[index].product_name_arabic
      } else {
        name = this.products[index].product_name_english
      }
    }
    console.log(name)
    return name
  }
  reset() {
    this.total = 0;
    this.total_products = 0;
    this.cart_items = [];
    this.payable = 0;
    localStorage.removeItem('cart');
  }



  add_to_cart(item, quantity) {
    debugger
    let oldQ = 0;


    let index = this.cart_items.findIndex((i) => {
      return i.campaign.id == item.id
    })

    if (index >= 0) {
      oldQ = this.cart_items[index].quantity
    }

    if (quantity > (parseInt(item.total_stock) - oldQ - parseInt(item.total_sold))) {
      this.loading.toast('Quantity not available.', 'danger');
      return;
    }

    if (!this.data.isLoggedIn()) {
      let index = this.cart_items.findIndex((i) => {
        return i.campaign.id == item.id
      })
      console.log(index)
      if (index >= 0) {
        this.cart_items[index].quantity = this.cart_items[index].quantity + quantity
      } else {
        let v = {
          product: item.product,
          campaign: item,
          quantity: quantity
        }
        this.cart_items.push(v);
      }
      localStorage.setItem('cart', JSON.stringify(this.cart_items));
      this.update_total()
    } else {
      this.loading.present('Updating Cart').then(() => {
        this.data.post('add_to_cart', {
          campaign: item.id,
          quantity: quantity,
          product: item.product.id ? item.product.id : item.product
        }).subscribe(data => {
          this.loading.dismiss();
          this.cart_items = data['cart_items'];
          this.update_total()
        }, error => {
          this.loading.dismiss();
          console.log('error adding cart')
        })
      })
    }

  }

  is_item_in_cart(campaign) {
    let index = this.cart_items.findIndex((i) => {
      return i.campaign.id == campaign
    })

    if (index >= 0) {
      return true
    } else {
      return false
    }


  }
  cart_quantity_count(campaign) {
    let index = this.cart_items.findIndex((i) => {
      return i.campaign.id == campaign
    })
    if (index >= 0) {
      return this.cart_items[index].quantity
    } else {
      return 0;
    }


  }

  cart_quantity(mode, campaign) {

    if (mode == 'up') {
      let availale = parseInt(campaign.total_stock) - parseInt(campaign.total_sold)
      if (this.cart_quantity_count(campaign.id) >= availale) {
        this.loading.toast('Quantity not available.', 'danger');
        return;
      }
      if (this.data.isLoggedIn()) {
        let index = this.cart_items.findIndex((i) => {
          return i.campaign.id == campaign.id
        })
        let update = {
          mode: 'up',
          item: this.cart_items[index].id
        }
        this.loading.present('Updating Cart').then(() => {
          this.data.post('cart_quantity', update).subscribe(data => {
            this.cart_items = data['cart_items'];
            this.loading.dismiss();
            this.update_total();
          }, error => {
            this.loading.dismiss()
            console.log('error updating cart', error)
          })
        })
      } else {
        let index = this.cart_items.findIndex((i) => {
          return i.campaign.id == campaign.id
        })
        this.cart_items[index].quantity = this.cart_items[index].quantity + 1;

        localStorage.setItem('cart', JSON.stringify(this.cart_items));
        this.update_total()
      }
    } else if (mode == 'down') {
      if (this.data.isLoggedIn()) {
        let index = this.cart_items.findIndex((i) => {
          return i.campaign.id == campaign.id
        })
        let update = {
          mode: 'down',
          item: this.cart_items[index].id
        }
        this.loading.present('Updating Cart').then(() => {
          this.data.post('cart_quantity', update).subscribe(data => {
            this.cart_items = data['cart_items'];
            this.loading.dismiss();
            this.update_total();
          }, error => {
            this.loading.dismiss()
            console.log('error updating cart', error)
          })
        })
      } else {
        let index = this.cart_items.findIndex((i) => {
          return i.campaign.id == campaign.id
        })
        if (this.cart_items[index].quantity == 1) {
          this.cart_items.splice(index, 1);

        } else {
          this.cart_items[index].quantity = this.cart_items[index].quantity - 1;
        }
        localStorage.setItem('cart', JSON.stringify(this.cart_items));
        this.update_total()
      }
    }
  }


  removeItem(item) {
    let campaign = item.campaign
    if (this.data.isLoggedIn()) {
      let index = this.cart_items.findIndex((i) => {
        return i.campaign.id == campaign.id
      })
      let update = {
        mode: 'remove',
        item: this.cart_items[index].id
      }
      this.loading.present('Updating Cart').then(() => {
        this.data.post('cart_quantity', update).subscribe(data => {
          this.cart_items = data['cart_items'];
          this.loading.dismiss();
          this.update_total();
        }, error => {
          this.loading.dismiss()
          console.log('error updating cart', error)
        })
      })
    } else {
      let index = this.cart_items.findIndex((i) => {
        return i.campaign.id == campaign.id
      })

      this.cart_items.splice(index, 1);


      localStorage.setItem('cart', JSON.stringify(this.cart_items));
      this.update_total()
    }
  }

  update() {
    localStorage.setItem('cart', JSON.stringify(this.cart_items))
  }


  update_total() {
    this.total = 0;
    this.payable = 0;
    this.total_products = 0;
    this.cart_items.forEach(item => {
      this.total += item.campaign.price * item.quantity
      this.payable += item.campaign.price * item.quantity
      this.total_products += item.quantity

    })
    this.loading.publishSomeData({
      cart: 'updated'
    });
  }


}
