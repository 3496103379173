import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
 
 
 
@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  auth = false;
  constructor(private router: Router) {
    let token; 
    token = localStorage.getItem('token');
    
    if(token == null){
      this.auth = false;
    }
    else if(token == 'undefined'){
      this.auth = false;
    }
    else if(token == ''){
      this.auth = false;
    }else{
      this.auth = true;
    }
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(this.auth){
      return true;
    } else{
      this.router.navigate(['/login']);
      return false
    }
  }


  logout(){
    this.auth = false;
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  is_logged_in(){
    let token; 
    token = localStorage.getItem('token');
    
    if(token == null){
      this.auth = false;
      return false
    }
    else if(token == 'undefined'){
      this.auth = false;
      return false
    }
    else if(token == ''){
      this.auth = false;
      return false
    }else{
      this.auth = true;
      return true;
    }
  }
}