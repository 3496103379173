import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ApolloModule } from 'apollo-angular';
 
import { HttpLinkModule } from 'apollo-angular-link-http';
 
import { CurrencyPipe } from '@angular/common';
import { MyCurrencyPipe } from './pipe/my-currency.pipe';
import { CartService } from './services/cart.service';
import { WishlistService } from './services/wishlist.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TruncatePipe } from './pipe/truncate.pipe';
 import { NgxSpinnerModule } from "ngx-spinner";
 import { Deeplinks } from '@ionic-native/deeplinks/ngx';


//  import { SocialLoginModule, AuthService } from 'angularx-social-login';
//  import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
 
import { AuthService, SocialLoginModule } from 'angularx-social-login';

import {
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { WindowRefService } from './services/window-ref.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      '420977011406-m3uj476llmpmirlrdeag78d01of3h4jp.apps.googleusercontent.com'
    ),
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('1485158921818241'),
  },
]);
 
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [AppComponent,MyCurrencyPipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({animated: false}),
    AppRoutingModule,
    ApolloModule,
    HttpLinkModule,
    HttpClientModule,
    SocialLoginModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({  
      loader: {   
        provide: TranslateLoader, 
        useFactory: (createTranslateLoader),  
        deps: [HttpClient]  
      }  
    }), BrowserAnimationsModule  
  ],
  providers: [
    StatusBar,
    CurrencyPipe,
    InAppBrowser,
    AuthService,
    CartService,
    WishlistService,
    MyCurrencyPipe,
    SplashScreen,
    Deeplinks,
    WindowRefService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
