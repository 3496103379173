import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthGuardService } from './auth-guard.service';
import { MyCurrencyPipe } from '../pipe/my-currency.pipe';
@Injectable({
  providedIn: 'root'
})
export class DataService {

     bs = "https://api.ifast.ae";
 base_url = "https://api.ifast.ae/";
   hs = "https://ifast.ae"

  selected_currency;
  constructor(private http: HttpClient, private _sanitizer: DomSanitizer, public auth: AuthGuardService, public currency: MyCurrencyPipe) { }

  safe_url(link) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  public validate_user(val) {
    return this.http.get(this.base_url + 'users/me', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + val
      })
    })
  }

  public validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public hasNumber(myString) {
    return /\d/.test(myString);
  }

  setCurrency() {
    this.http.get(this.base_url + 'currencies', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe(data => {
      console.log(data);

      localStorage.setItem('currency', JSON.stringify(data))
      this.set_default_currency()
    })
  }

  set_default_currency() {
    let dataa = localStorage.getItem('currency');
    let data: [] = JSON.parse(dataa);
    if (localStorage.getItem('default_currency')) {
      let index = data.findIndex((i) => {
        return i['currency_code'] == localStorage.getItem('default_currency')
      })
      this.selected_currency = data[index]
    } else {
      let index = data.findIndex((i) => {
        return i['is_default'] == true
      })
      this.selected_currency = data[index];
      localStorage.setItem('default_currency', this.selected_currency.currency_code)
    }
  }

  format_currency(val) {
    if (this.selected_currency) {
      return this.currency.transform(this.selected_currency['conversion_rate'] * val, this.selected_currency['currency_code'])
    }
  }

  change_currency(c) {
    let dataa = localStorage.getItem('currency');
    let data: [] = JSON.parse(dataa);
    let index = data.findIndex((i) => {
      return i['currency_code'] == c
    })
    this.selected_currency = data[index]
    localStorage.setItem('default_currency', this.selected_currency.currency_code)
  }

  public saveToLocal(data) {
    localStorage.setItem('vals', JSON.stringify(data));
  }

  isLoggedIn() {
    return this.auth.is_logged_in();
  }

  public getLocal(key, additional = null) {
    let data = localStorage.getItem('vals');
    data = JSON.parse(data);
    data = data[key];
    if (additional != null) {
      data = data[0][additional]
    }
    return data;
  }

  public register(data) {
    return this.http.post(this.base_url + 'auth/local/register', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public sendotp(data) {
    return this.http.post(this.base_url + 'joy_test', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  public verifyotp(data) {
    return this.http.post(this.base_url + 'verify_otp', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public socialRegister(data) {
    return this.http.post(this.base_url + 'auth/local/socialRegister', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public login(data) {
    return this.http.post(this.base_url + 'auth/local', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  public socialLogin(data) {
    return this.http.post(this.base_url + 'auth/socialLocal', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  public post(url, data) {
    return this.http.post<any[]>(this.base_url + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }


  public post_open(url, data) {
    return this.http.post<any[]>(this.base_url + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  public put(url, data) {
    return this.http.put(this.base_url + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }

  public get(url) {
    return this.http.get<any[]>(this.base_url + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }

  public get_public(url) {
    return this.http.get<any[]>(this.base_url + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  public delete(url) {
    return this.http.delete(this.base_url + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }


  postFile(fileToUpload: File) {
    const endpoint = this.base_url + 'upload';
    const formData: FormData = new FormData();
    formData.append('files', fileToUpload);
    return this.http
      .post(endpoint, formData, {
        headers: new HttpHeaders({

          'Authorization': `Bearer ` + localStorage.getItem('token')
        })
      })


  }


  postFiles(files: FileList) {

    const endpoint = this.base_url + 'upload';
    const formData: FormData = new FormData();
    let x = 0;
    for (x = 0; x < files.length; x++) {
      formData.append('files', files[x]);
    }

    return this.http
      .post<any[]>(endpoint, formData, {
        headers: new HttpHeaders({

          'Authorization': `Bearer ` + localStorage.getItem('token')
        })
      })


  }


}

