import { Injectable, ViewContainerRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {Subject} from 'rxjs';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  header_class = '';
  _loading 
  selected_lang = ''
  private fooSubject = new Subject<any>();
  constructor(public translate : TranslateService ,private title: Title, private meta: Meta,  public toastController: ToastController, public loadingcontroller : LoadingController,public alertController : AlertController,public router : Router,
    public activatedRoute : ActivatedRoute) { 
     
  }

  set_mea(){
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
       console.log(event)
       this.updateTitle(event['title']);
       this.updateOgUrl(event['ogUrl']);
       this.updateKeyWords(event['keywords']);
       //Updating Description tag dynamically with title
       this.updateDescription(event['title'] + event['description'])
     }); 
  }
  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateKeyWords(key : string){
    this.meta.updateTag({name : 'keywords' , content : key})
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  async present(data) {
    this._loading = await this.loadingcontroller.create({
      message: this.translate.instant(data),
       duration: 3000
    });
    await this._loading.present();
    console.log(this._loading)
 
  }

  async dismiss() {
    const loadingExist = document.getElementsByTagName('ion-loading')[0];
    if(loadingExist){
      return await this.loadingcontroller.dismiss();
    }
   
  }


  async alert(header , message){
    const alert = await this.alertController.create({
      header: this.translate.instant(header),
      message: this.translate.instant(message),
    });

    await alert.present();
  }


  publishSomeData(data: any) {
      this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
      return this.fooSubject;
  }


  logScrolling(data){
    

    if(data.detail.scrollTop > 50){
      this.header_class = 'affix'
    }else{
      this.header_class = ''
    }
  }

 async toast(msg,type){
    const toast = await this.toastController.create({
      message: this.translate.instant(msg),
      duration: 2000,
      position : "top",
      color : type,
      cssClass : 'cus_toast'
    });
    toast.present();
  }


  share(url = null , platform = null){
    console.log(platform)
    if(url != null && platform != null){
      if(platform == 'facebook'){
      //  e.preventDefault();
        var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook-popup', 'height=350,width=600');
        if(facebookWindow.focus) { facebookWindow.focus(); }
          return false;
      }
    }else{
      return false;
    }
  }

}
