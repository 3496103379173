import { Component, ElementRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from './services/utilities.service';
import { Router ,NavigationEnd, NavigationStart } from '@angular/router';
import { DataService } from './services/data.service';
import { CartService } from './services/cart.service';
import { WishlistService } from './services/wishlist.service';
import { GraphQlService } from './services/graph-ql.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html', 
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public translate: TranslateService,
    public loading : UtilitiesService,
    public router : Router,
    public data : DataService,
    public cart : CartService,
    public wish : WishlistService,
    public graphql : GraphQlService,
    public elementRef:ElementRef
  ) {

    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //   // true for mobile device
    //   if(this.platform.is('android')){
    //  this.router.navigate([]).then(result => {  window.open('https://play.google.com/store/apps/details?id=alphabitapp.winmall.ae', '_blank'); });
    //       console.log('android')
    //   } else {
    //   this.router.navigate([]).then(result => {  window.open('https://apps.apple.com/in/app/winmall-uae/id1567371893', '_blank'); });
    //   console.log('ios')
    //   }
    //   document.write("mobile device");
    //   console.log("mobile device")
    // }else{
    //   // false for not mobile device
    //   document.write("not mobile device");
    //   console.log("not mobile device")
    // }

    this.initializeApp();
    this.load_products();
    this.data.setCurrency()
    this.langs();
    this.loading.set_mea();
  }

  
  initializeApp() {
    this.platform.ready().then(() => {
     // this.statusBar.styleDefault();
     // this.splashScreen.hide();
    });
     
    
  }


  langs(){
    this.translate.addLangs(['en', 'ar']);
    console.log('browser Language' ,this.translate.getBrowserLang())
    this.translate.setDefaultLang('en');
     
      if(!localStorage.getItem('lang')){
        
          localStorage.setItem('lang','en')
        
      }else{
        if(localStorage.getItem('lang') == 'ar'){
          document.documentElement.dir = "rtl";
          document.body.classList.add('rtl')
          document.body.classList.remove('ltr')
        }
      }
   
      if(this.translate.getBrowserLang() == 'ar'){
        document.documentElement.dir = "rtl";
        document.body.classList.add('rtl')
        document.body.classList.remove('ltr')
      }

    this.loading.selected_lang = localStorage.getItem('lang')
    this.translate.use(this.loading.selected_lang);

    this.loading.getObservable().subscribe(data => {
      
      if(data['lang'] == 'ar'){
        localStorage.setItem('lang','ar')
        document.documentElement.dir = "rtl";
        document.body.classList.add('rtl')
        document.body.classList.remove('ltr')
        this.loading.selected_lang = 'ar'
        this.translate.use(data['lang']);
        // window.location.reload()
      }else if(data['lang'] == 'en'){
        localStorage.setItem('lang','en')
        document.documentElement.dir = "ltr";
        document.body.classList.remove('rtl')
        document.body.classList.add('ltr')
        this.loading.selected_lang = 'en'
        this.translate.use(data['lang']);
        // window.location.reload()
      }

      
      
    })

  }

  load_products(){
    let q = `query{
      products{
        id
        product_name_english
        product_name_arabic
        product_images{
          id
          url
        }
      }
    }`;

    this.graphql.get_public(q).subscribe(data => {
      localStorage.setItem('products_system',JSON.stringify(data['data']['products']))
       
    })
  }

}
